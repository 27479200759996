import React, { useState } from 'react';
import ClaimButton from '../../../../components/ClaimButton';
import PropTypes from 'prop-types';
import {
  PartialModalOverlayTitle,
  PartialModalOverlayBody,
  PartialModalOverlay,
  colorPalette,
  Input,
} from 'ri-components';
import { makeStyles, Typography } from '@material-ui/core';
import useAccessibility from '../../hooks/useAccessibility';
import { useFormik } from 'formik';
import { EventList } from '../../utils/EventList';
import { hooks } from 'botframework-webchat-component';
import { EditTypes } from '../../utils/EditTypes';
import { minMaxValidation, isTextualKeyboard } from '../../utils/validation-utils';

const { white } = colorPalette;
const { useSendEvent } = hooks;

const useStyles = ({ from, fromId }) =>
  makeStyles((theme) => ({
    previousAnswerBubble: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: theme.spacing(2.75),
      padding: '12px',
      display: 'inline-block',
      marginBottom: '24px',
    },
    editInput: {
      marginBottom: '24px',
      '& input': {
        borderRadius: '24px',
        border: 'solid 1px #dadce1',
        backgroundColor: white,
        padding: '12px 16px',
      },
      '& p': {
        color: '#D91A21',
      },
    },
    newAnswer: {
      marginBottom: '8px',
    },
    saveButton: {
      marginBottom: '10px',
    },
    messageTypography: from === 'user' || fromId === 'user' ? theme.message.user : theme.message.bot,
    charsLeft: {
      fontSize: '16px',
      color: 'grey',
      marginRight: '10px',
      textAlign: 'right',
    },
  }));

const EditModalTextInput = ({
  editLabels,
  editableKey,
  editValueType,
  from,
  fromId,
  children,
  openModalOverlay,
  setOpenModalOverlay,
  validationRegex,
  arrayIndexKey,
  arrayKey,
  editType,
  validationMessage,
  minLength,
  minLengthValidationMsg,
  maxLength,
  maxLengthValidationMsg,
  keyboardType,
}) => {
  const typographyVariant = from === 'user' || fromId === 'user' ? 'body2' : 'body1';
  const classes = useStyles({ from, fromId, editableKey })();
  const { newAnswerInput } = useAccessibility();
  const sendEvent = useSendEvent();

  const [charsLeft, setCharsLeft] = useState(
    isTextualKeyboard(keyboardType) && !isNaN(parseInt(maxLength)) ? parseInt(maxLength) : 0,
  );

  function validateMessage(value) {
    let errorMessage = minMaxValidation(
      value,
      minLength,
      maxLength,
      minLengthValidationMsg,
      maxLengthValidationMsg,
      keyboardType,
      setCharsLeft,
    );
    if (errorMessage) return errorMessage;

    let validMessageRegex = validationRegex?.includes('\\p{L}')
      ? new RegExp(validationRegex, 'u')
      : new RegExp(validationRegex);

    if (value && !validMessageRegex.test(value)) return validationMessage;
  }

  const formik = useFormik({
    initialValues: {
      editValue: null,
    },
    onSubmit: (values, { resetForm }) => {
      sendEvent(EventList.EditAnswerEvent, {
        editableKey: editableKey,
        editValue: values.editValue,
        arrayKey: arrayKey,
        arrayIndexKey: arrayIndexKey,
        editType: editType ?? EditTypes.Text,
        editValueType: editValueType,
      });
      resetForm({ values: '' });
      setOpenModalOverlay(false);
    },
    validate: (values) => {
      let errors = {};
      if (validateMessage(values.editValue)) {
        errors.editValue = validateMessage(values.editValue);
      }
      return errors;
    },
  });
  return (
    <PartialModalOverlay open={openModalOverlay} onClose={() => setOpenModalOverlay(false)}>
      <PartialModalOverlayTitle>{editLabels && editLabels.header}</PartialModalOverlayTitle>
      <PartialModalOverlayBody>{editLabels && editLabels.previousAnswerLabel} </PartialModalOverlayBody>
      <div>
        <div
          className={classes.previousAnswerBubble}
          data-testid='message-bubble'
          style={{ display: 'inline-block' }}
        >
          <Typography
            component={'p'}
            variant={typographyVariant}
            data-testid='text-plain'
            className={classes.messageTypography}
          >
            {children}
          </Typography>
        </div>
        <Typography variant='subtitle2' className={classes.newAnswer}>
          {editLabels && editLabels.newAnswerLabel}
        </Typography>
        <div className={classes.editInput}>
          {isTextualKeyboard(keyboardType) && maxLength && (
            <div className={classes.charsLeft} id='edit-chars-left'>
              {charsLeft}
            </div>
          )}
          <Input
            type='text'
            id='edit-message'
            autoFocus
            tabIndex='0'
            value={formik.values.editValue}
            onChange={formik.handleChange}
            onBlur={(ev) => formik.setFieldValue('editValue', ev.target.value.trim())}
            fullWidth
            name='editValue'
            placeholder={editLabels && editLabels.placeHolder}
            aria-label={newAnswerInput}
            errorMsg={formik.errors.editValue}
            maxLength={isTextualKeyboard(keyboardType) && maxLength}
          />
        </div>
        <div className={classes.saveButton}>
          <ClaimButton
            fullWidth
            disabled={!formik.values.editValue || !formik.isValid}
            label={editLabels && editLabels.saveButton}
            handleClick={formik.handleSubmit}
            externalClassName='external-modal-button'
          />
        </div>
        <div>
          <ClaimButton
            label={editLabels && editLabels.cancelButton}
            fullWidth
            externalClassName='external-modal-button'
            handleClick={() => {
              setOpenModalOverlay(false);
              formik.resetForm();
            }}
          />
        </div>
      </div>
    </PartialModalOverlay>
  );
};

export default EditModalTextInput;

EditModalTextInput.propTypes = {
  children: PropTypes.node,
  editableKey: PropTypes.string,
  editValueType: PropTypes.string,
  arrayKey: PropTypes.string,
  arrayIndexKey: PropTypes.number,
  validationRegex: PropTypes.string,
  validationMessage: PropTypes.string,
  editLabels: PropTypes.object,
  from: PropTypes.string,
  fromId: PropTypes.string,
  editType: PropTypes.string,
  setOpenModalOverlay: PropTypes.func,
  openModalOverlay: PropTypes.bool,
  minLength: PropTypes.string,
  minLengthValidationMsg: PropTypes.string,
  maxLength: PropTypes.string,
  maxLengthValidationMsg: PropTypes.string,
  keyboardType: PropTypes.string,
};
