import React, { useCallback, useState, useEffect, useRef, useContext } from 'react';
import { hooks } from 'botframework-webchat-component';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import useSendBox from './hooks/useSendBox';
import useAccessibility from './hooks/useAccessibility';
import useHideScrollBarStyles from '../../themes/styles/hideScrollBar';
import { MD_LAYOUT_BREAKPOINT } from '../../themes/GlobalThemeProvider';
import { colorPalette } from 'ri-components';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useLastActivity from './hooks/useLastActivity';
import SendBoxInputContext from './SendBoxInput/SendBoxInputContext';
import { minMaxValidation, isTextualKeyboard } from './utils/validation-utils';
import { encryptMessage } from './utils/encryption';
import { useOauthApiConfigs } from '../../services/TenantOauthContextProvider';

const { white } = colorPalette;
const { useSendMessage, usePostActivity, useSendMessageBack } = hooks;

const StyledIconButton = withStyles((theme) => ({
  root: {
    height: '32px',
    width: '32px',
    color: white,
    padding: '0px',
    backgroundColor: theme.palette.success.main,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      color: white,
      backgroundColor: '#999b9f',
    },
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}))(IconButton);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.25, 3, 2.5, 3),
    [theme.breakpoints.up(MD_LAYOUT_BREAKPOINT)]: {
      padding: theme.spacing(2.5, 3),
    },
  },
  sendBox: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '24px',
    padding: theme.spacing(0.875, 1, 0.875, 2),
    border: 'solid 1px #dadce1',
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
    },
  },
  sendBoxDefaultBackgroundColor: {
    backgroundColor: white,
  },
  sendBoxDisabledBackgroundColor: {
    backgroundColor: '#f2f3f4',
  },
  sendBoxInput: {
    width: '100%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    background: 'transparent',
    minHeight: '20px',
    maxHeight: '120px',
    fontSize: '18px',
    letterSpacing: '-0.2px',
    padding: '0px',
  },
  iconButton: {
    alignSelf: 'flex-end',
  },
  sendBoxCharsLeft: {
    fontSize: '16px',
    color: '#959292',
    marginRight: '10px',
  },
  sendBoxValidationText: {
    fontSize: '15px',
    color: '#D91A21',
    fontWeight: 500,
  },
}));

const SendBox = ({ sendBoxContainerRef }) => {
  const classes = useStyles();
  const { hideScrollBar } = useHideScrollBarStyles();
  const sendMessage = useSendMessage();
  const postActivity = usePostActivity();
  const oauthConfigs = useOauthApiConfigs();
  const sendMessageBack = useSendMessageBack();
  const lastActivity = useLastActivity();
  const [typing, setTyping] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [charsLeft, setCharsLeft] = useState(0);
  const textareaRef = useRef(null);
  const hasAutoFocus = window.screen.width > 480;
  
  const { isSendboxActive, placeholderText, keyboardType, removeSpaces } = useSendBox();
  

  const [sendBoxBackgroundColor, setSendBoxBackgroundColor] = useState(classes.sendBoxDefaultBackgroundColor);
  const { setValue: setInputValue, inputValue } = useContext(SendBoxInputContext);
  useEffect(() => {
    const newSendBoxBackgroundColor = isSendboxActive
      ? classes.sendBoxDefaultBackgroundColor
      : classes.sendBoxDisabledBackgroundColor;
    setSendBoxBackgroundColor(newSendBoxBackgroundColor);

    if (!isSendboxActive) {
      setInputValue('');
      setTyping(false);
    }

    if (isSendboxActive && hasAutoFocus) {
      textareaRef.current.focus();
    }
  }, [isSendboxActive, hasAutoFocus, classes, setInputValue, textareaRef]);

  useEffect(() => {
    textareaRef.current.style.height = '0px';
    const scrollHeight = textareaRef.current.scrollHeight;
    textareaRef.current.style.height = scrollHeight + 'px';
  }, [inputValue, textareaRef]);

  useEffect(() => {
    setCharsLeft(
      isTextualKeyboard(keyboardType) &&
        !isNaN(parseInt(lastActivity?.maxlength)) &&
        parseInt(lastActivity?.maxlength),
    );
  }, [lastActivity, keyboardType]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setTyping(!!value);
      setInputValue(value);
      setValidationError(minMaxValidationCallback(value));
    },
    [setTyping, setInputValue, setValidationError, minMaxValidationCallback],
  );

  const minMaxValidationCallback = useCallback(
    (value) =>
      minMaxValidation(
        value,
        lastActivity.minlength,
        lastActivity.maxlength,
        lastActivity.minlengthvalidationmsg,
        lastActivity.maxlengthvalidationmsg,
        keyboardType,
        setCharsLeft,
      ),
    [keyboardType, lastActivity, setCharsLeft],
  );

  //remove special characters so it does not break our ADF
  const sanitize = useCallback(
    (input) => {
      let result = input
        .trim()
        .replace(/\\n/g, '')
        .replace(/\\r/g, '')
        .replace(/\n/g, '')
        .replace(/\r/g, '')
        .replace(/;/g, '');

      if (removeSpaces) {
        result = result.replace(/ /g, '');
      }

      return result;
    },
    [removeSpaces],
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      let sanitizedInput = sanitize(inputValue);

      if (sanitizedInput) {
        const activity = {
          value: sanitizedInput,
          text: sanitizedInput,
          pdfLabel: lastActivity.pdflabel,
          pdfValue: sanitizedInput,
          type: 'message',
        };
        if (lastActivity.editablekey) {
          if (lastActivity.validationregex && Array.isArray(lastActivity.validationregex)) {
            lastActivity.validationregex = lastActivity.validationregex.join('|');
          }
          postActivity({
            ...activity,
            editableKey: lastActivity.editablekey,
            arrayKey: lastActivity.arraykey,
            arrayIndexKey: lastActivity.arrayindexkey,
            validationRegex: lastActivity.validationregex,
            editLabels: lastActivity.editlabels,
            editType: lastActivity.edittype,
            editValueType: lastActivity.editvaluetype,
            validationMessage: lastActivity.validationerrormsg
              ? lastActivity.validationerrormsg
              : lastActivity.text,
            editDependencies: lastActivity.editdependencies,
            editTemplate: lastActivity.edittemplate,
            maskType: lastActivity.masktype,
            minLength: lastActivity.minlength,
            minLengthValidationMsg: lastActivity.minlengthvalidationmsg,
            maxLength: lastActivity.maxlength,
            maxLengthValidationMsg: lastActivity.maxlengthvalidationmsg,
            keyboardType: lastActivity.keyboardtype,
            hiddenIconsStatuses: lastActivity.hiddeniconsstatuses,
          });
        } else if (lastActivity.masktype) {
          postActivity({
            ...activity,
            maskType: lastActivity.masktype,
          });
        } else if (lastActivity.pdflabel) {
          postActivity(activity);
        } else {
          if (lastActivity.encrypt) {
            const encryptedValue = encryptMessage(sanitizedInput, oauthConfigs.publicKey);
            const secretChar = '*';
            sendMessageBack(
              encryptedValue,
              encryptedValue,
              sanitizedInput.replace(sanitizedInput, secretChar.repeat(sanitizedInput.length)),
            );
          } else {
            sendMessage(sanitizedInput);
          }
        }
        setInputValue('');
        setTyping(false);
      }
    },
    [
      inputValue,
      postActivity,
      setInputValue,
      setTyping,
      lastActivity,
      sendMessage,
      sanitize,
      sendMessageBack,
      oauthConfigs,
    ],
  );

  const handleEnterKeyPress = (e) => {
    if (!validationError && e.keyCode === 13 && e.shiftKey === false) {
      handleSubmit(e);
    } else if (e.keyCode === 13 && !e.shiftKey) e.preventDefault();
  };

  const { selectOptionAbove } = useAccessibility();
  const textAreaStyle = lastActivity?.encrypt ? {
    WebkitTextSecurity: 'disc',
    MozTextSecurity: 'disc',
    textSecurity: 'disc',
  } :{};

  return (
    <div className={classes.root} ref={sendBoxContainerRef}>
      <div
        className={clsx(classes.sendBox, sendBoxBackgroundColor)}
        id='send-box'
        style={{ display: lastActivity?.issendboxdisabled ? 'none' : 'flex' }}
      >
        <textarea
          ref={textareaRef}
          inputMode={keyboardType}
          disabled={!isSendboxActive}
          autoFocus={hasAutoFocus}
          className={clsx(classes.sendBoxInput, hideScrollBar)}
          onChange={handleChange}
          onKeyDown={handleEnterKeyPress}
          placeholder={placeholderText}
          tabIndex='0'
          value={inputValue}
          style={textAreaStyle}
          aria-label={isSendboxActive ? null : selectOptionAbove}
          maxLength={isTextualKeyboard(keyboardType) && lastActivity?.maxlength}
        />
        {isTextualKeyboard(keyboardType) && lastActivity?.maxlength && (
          <span id='send-box-chars-left' className={classes.sendBoxCharsLeft}>
            {charsLeft}
          </span>
        )}
        <StyledIconButton
          key={typing}
          className={classes.iconButton}
          disableRipple
          disabled={!typing || !!validationError}
          onClick={handleSubmit}
          aria-label='Send'
          id='send-button'
        >
          <ArrowForwardIcon />
        </StyledIconButton>
      </div>
      {validationError && (
        <div id='send-box-validation-error-text' className={classes.sendBoxValidationText}>
          {validationError}
        </div>
      )}
    </div>
  );
};

SendBox.propTypes = {
  sendBoxContainerRef: PropTypes.object.isRequired,
};

export default SendBox;
