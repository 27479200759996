import React, { useRef, useState } from 'react';
import { Select, Input } from 'ri-components';
import ClaimButton from '../../../../components/ClaimButton';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { MD_LAYOUT_BREAKPOINT } from '../../../../themes/GlobalThemeProvider';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios';
import { useApiConfigs } from '../../../../services/ApiConfigsContextProvider';
import { colorPalette } from 'ri-components';
import useAccessibility from '../../hooks/useAccessibility';
import { getAdministrativeArea, getCountry } from '../google-maps/GoogleMapsHelper';

const { white } = colorPalette;

const defaultAddressInputValues = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  townOrCity: '',
  postCode: '',
};

const useStyles = makeStyles((theme) => ({
  menuItemRoot: {
    overflow: 'unset',
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
    '&:focus': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
  selectedMenuItem: {
    overflow: 'unset',
    backgroundColor: '#D1D1D1',
    outline: '2px auto black',
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
  selectLocationButton: {
    margin: '20px',
  },
  addressSearchFailedClass: {
    color: 'red',
    fontSize: '14px',
    marginBottom: '10px',
  },
  root: {
    '& > div': {
      overflow: 'hidden !IMPORTANT',
      border: '1px solid black',
      '&:active': {
        outline: '2px solid black',
        boxShadow: 'none',
      },
      '&:focus-within': {
        border: '1px solid black',
        overflow: 'hidden !IMPORTANT',
        outline: '2px solid black',
        boxShadow: 'none ! IMPORTANT',
      },
    },
  },
  selectRoot: {
    '& > div': {
      overflow: 'hidden !IMPORTANT',
    },
    marginBottom: '10px',
  },
  paper: {
    top: '51vh !important',
    maxHeight: '40vh',
    minWidth: '630px',
    '@media (max-width: 780px)': {
      width: '86vw',
      left: '7vw !IMPORTANT',
      right: '7vw !IMPORTANT',
      minWidth: '100px !IMPORTANT',
    },
    '@media (max-width: 420px)': {
      width: '72vw',
      left: '14vw !IMPORTANT',
      right: '14vw !IMPORTANT',
      minWidth: '100px !IMPORTANT',
    },
  },
  selectMenuList: {
    overflow: 'auto',
    display: 'grid',
  },
  containerElement: {
    height: `400px`,
    marginBottom: '20px',
    width: '100%',
    padding: '0 20px',
    [theme.breakpoints.down(MD_LAYOUT_BREAKPOINT)]: {
      height: '240px',
      padding: '0px',
    },
  },
  loadingElement: { height: `100%` },
  mapElement: { height: `100%` },
  mapContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    paddingBottom: '10px',
    width: '100%',
    [theme.breakpoints.between(0, 350)]: {
      maxWidth: '240px',
    },
    [theme.breakpoints.between(350, 420)]: {
      maxWidth: '290px',
    },
  },
  sendBox: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '24px',
    maxWidth: '720px',
    padding: theme.spacing(0.875, 1, 0.875, 2),
    border: 'solid 1px #dadce1',
    marginBottom: '10px',
  },
  addressInput: {
    width: '80vw',
    maxWidth: '400px',
    border: 'solid 1px #dadce1;',
    outline: 'none',
    resize: 'none',
    background: 'transparent',
    minHeight: '35px',
    maxHeight: '120px',
    fontSize: '18px',
    letterSpacing: '-0.2px',
    padding: '0px',
    borderRadius: '24px',
    paddingLeft: '10px',
    margin: '3px',
  },
  sendBoxInput: {
    width: '100%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    background: 'transparent',
    minHeight: '20px',
    maxHeight: '120px',
    fontSize: '18px',
    letterSpacing: '-0.2px',
    padding: '0px',
  },
}));

const StyledIconButton = withStyles((theme) => ({
  root: {
    height: '32px',
    width: '32px',
    color: white,
    padding: '0px',
    backgroundColor: theme.palette.primary.main,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      color: white,
      backgroundColor: '#999b9f',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(IconButton);

const AutocompleteAddressSearch = ({ onLocationSelected, mapLabels, locationNotListed, setlocationNotListed }) => {
  const { webApiUrl } = useApiConfigs();
  const [selectedAddress, setSelectedAddress] = useState('');
  const [addressSearched, setAddressSearched] = useState(false);
  const [summaryAddress, setSummaryAddress] = useState('');

  const [addressInputs, setAddressInputs] = useState({
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    townOrCity: '',
    postCode: '',
  });
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressSearchFailed, setaddressSearchFailed] = useState(false);
  const classes = useStyles();
  const inputRef = useRef();
  const [autocompleteAddress, setAutocompleteAddress] = useState('');
  const geocoder = useRef();

  const onLocationSearch = (ev) => {
    setAutocompleteAddress(ev.target.value);
  };

  const onKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      selectTypedLocationHandler();
    }
  };

  const getLatAndLogForSelectedAddress = () => {
    geocoder.current = new window.google.maps.Geocoder();
    let _summaryAddress = summaryAddress;

    if (locationNotListed) {
      var addrr = new Array();
      if (addressInputs.addressLine1) addrr.push(addressInputs.addressLine1);
      if (addressInputs.addressLine2) addrr.push(addressInputs.addressLine2);
      if (addressInputs.addressLine3) addrr.push(addressInputs.addressLine3);
      if (addressInputs.townOrCity) addrr.push(addressInputs.townOrCity);
      if (addressInputs.postCode) addrr.push(addressInputs.postCode);
      _summaryAddress = addrr.join(', ');

      setSummaryAddress(_summaryAddress);
    }

    geocoder.current.geocode({ address: _summaryAddress }, (results, status) => {
      if (status === 'OK') {
        onLocationSelectedHandler(
          _summaryAddress,
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng(),
          getCountry(results[0]),
          getAdministrativeArea(results[0]),
        );
      } else {
        onLocationSelectedHandler(_summaryAddress, '', '', '');
      }
    });
  };

  const onLocationSelectedHandler = (_summaryAddress, lat, lng, country, administrativeArea) => {
    onLocationSelected([
      { key: `conversation.claim.addressName.SummaryAddress`, value: _summaryAddress ?? '' },
      { key: `conversation.claim.addressName.AddressLine1`, value: addressInputs.addressLine1 ?? '' },
      { key: `conversation.claim.addressName.AddressLine2`, value: addressInputs.addressLine2 ?? '' },
      { key: `conversation.claim.addressName.AddressLine3`, value: addressInputs.addressLine3 ?? '' },
      { key: `conversation.claim.addressName.TownOrCity`, value: addressInputs.townOrCity ?? '' },
      { key: `conversation.claim.addressName.PostCode`, value: addressInputs.postCode ?? '' },
      { key: `conversation.claim.addressName.Country`, value: country ?? '' },
      { key: `conversation.claim.addressName.AdministrativeArea`, value: administrativeArea ?? '' },
      { key: `conversation.claim.addressName.Latitude`, value: lat },
      { key: `conversation.claim.addressName.Longitude`, value: lng },
      {
        key: `conversation.claim.addressName.ShortAddress`,
          value: `${addressInputs.addressLine1}, ${addressInputs.addressLine2 ? addressInputs.addressLine2 +',':''} ${addressInputs.townOrCity}`,
      },
    ]);
  };
  const onLocationNotListeHandler = () => {
    setSummaryAddress('');
    setAutocompleteAddress('');
    setlocationNotListed(true);
    setAddressInputs(defaultAddressInputValues);
    setSelectedAddress('');
  };

  const onBackToSeachAddressHandler = () => {
    setlocationNotListed(false);
    setAddressSearched(false);
    setSummaryAddress('');
    setAutocompleteAddress('');
    setAddressInputs(defaultAddressInputValues);
    setSelectedAddress('');
  };

  const setAddressInputValue = (key, value) => {
    let updatedValue = {};
    updatedValue = { [key]: value };

    setAddressInputs((addressInputs) => ({
      ...addressInputs,
      ...updatedValue,
    }));
  };

  const addressSelectedHandler = (event) => {
    setSelectedAddress(event.target.value);
    if (event.target.value === 'DEFAULT') return;
    const _selectedAddress = addressOptions.filter((x) => x.udprn === event.target.value);
    setSummaryAddress(_selectedAddress[0].summaryAddress);

    const thereIsAddressLinePremisesAndStreet =
      _selectedAddress[0].formattedAddressLines.premises && _selectedAddress[0].formattedAddressLines.street;

    const _addressLine1 =
      _selectedAddress[0].formattedAddressLines.premises ?? _selectedAddress[0].formattedAddressLines.street;
    const _addressLine2 = thereIsAddressLinePremisesAndStreet
      ? _selectedAddress[0].formattedAddressLines.street
      : _selectedAddress[0].formattedAddressLines.locality;
    const _addressLine3 = thereIsAddressLinePremisesAndStreet
      ? _selectedAddress[0].formattedAddressLines.locality
      : '';

    setAddressInputs({
      addressLine1: _addressLine1,
      addressLine2: _addressLine2,
      addressLine3: _addressLine3,
      townOrCity: _selectedAddress[0].formattedAddressLines.postTown,
      postCode: _selectedAddress[0].formattedAddressLines.postcode,
    });
  };

  const selectTypedLocationHandler = () => {
    axios
      .get(`${webApiUrl}/api/vehicleuk/getaddressbypostcode/${autocompleteAddress}`)
      .then((res) => {
        if (res.data.response.dataItems.addressDetails) {
          setAddressOptions(res.data.response.dataItems.addressDetails.addressList);
        } else {
          setAddressOptions([]);
        }
        setSelectedAddress('DEFAULT');
        setAddressSearched(true);
        return res;
      })
      .catch(() => {
        setAddressOptions([]);
        setSelectedAddress('DEFAULT');
        setAddressSearched(true);
        setaddressSearchFailed(true);
        defaultAddressInputValues.postCode = autocompleteAddress;
        onLocationNotListeHandler();
      });
  };

  const labels = useAccessibility();

  const addressInputAriaLabels = {
    // accessibility
    addressLine1: labels.addressLineInput + ' 1',
    addressLine2: labels.addressLineInput + ' 2',
    addressLine3: labels.addressLineInput + ' 3',
    townOrCity: labels.townOrCityInput,
    postCode: labels.postCodeInput,
  };

  return (
    <div className={classes.container}>
      {!locationNotListed && (
        <div className={classes.input}>
          <div className={classes.root}>
            <div className={`${classes.sendBox} modal-input-answer`}>
              <input
                ref={inputRef}
                autoFocus
                tabIndex='0'
                placeholder={mapLabels.placeHolder}
                className={`${classes.sendBoxInput}`}
                value={autocompleteAddress ?? ''}
                onKeyPress={onKeyPress}
                onChange={onLocationSearch}
                aria-label={labels.postCodeInput}
              />
              <StyledIconButton
                onClick={selectTypedLocationHandler}
                className={`${classes.iconButton} modal-send-button`}
                disabled={autocompleteAddress === ''}
                disableRipple
                aria-label='Send'
              >
                <ArrowForwardIcon />
              </StyledIconButton>
            </div>
          </div>
        </div>
      )}
      {addressSearched && !locationNotListed && (
        <div className={classes.input}>
          <div>
            <Select
              onChange={addressSelectedHandler}
              value={selectedAddress}
              className={`${classes.selectRoot} modal-dropdown-lg`}
              MenuProps={{
                classes: {
                  list: classes.selectMenuList,
                  paper: classes.paper,
                },
              }}
            >
              <MenuItem value='DEFAULT'>Select an address...</MenuItem>
              {addressOptions &&
                addressOptions.map((item) => {
                  return (
                    <MenuItem
                      key={item.udprn}
                      value={item.udprn}
                      classes={{ root: classes.menuItemRoot, selected: classes.selectedMenuItem }}
                    >
                      {item.summaryAddress}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
        </div>
      )}

      {locationNotListed && (
        <div className={classes.selectLocationButton}>
          {addressSearchFailed && mapLabels.addressSearchText && (
            <div className={classes.addressSearchFailedClass}>{mapLabels.addressSearchText}</div>
          )}
          {Object.keys(defaultAddressInputValues).map((key, index) => {
            return (
              <Input
                autoFocus={index === 0}
                id={`input-manual-address-${index}`}
                key={key}
                innerRef={inputRef}
                tabIndex='0'
                placeholder={mapLabels[key]}
                className={`${classes.addressInput} modal-input-answer`}
                value={addressInputs[key] ?? ''}
                onChange={(e) => setAddressInputValue(key, e.target.value)}
                aria-label={addressInputAriaLabels[key]}
              />
            );
          })}
        </div>
      )}
      <ClaimButton
        handleClick={getLatAndLogForSelectedAddress}
        disabled={
          addressInputs.addressLine1 === '' ||
          (addressInputs.townOrCity === '' && mapLabels['townOrCity'].indexOf('optional') < 0) ||
          (addressInputs.postCode === '' && mapLabels['postCode'].indexOf('optional') < 0) ||
          selectedAddress === 'DEFAULT'
        }
        className={classes.selectLocationButton}
        label={mapLabels.selectButton}
        externalClassName='external-modal-button confirm-address-button'
      />
      {!locationNotListed && (
        <ClaimButton
          handleClick={onLocationNotListeHandler}
          className={classes.selectLocationButton}
          label={mapLabels.AddressNotFound}
          externalClassName='external-modal-button'
        />
      )}
      {locationNotListed && (
        <ClaimButton
          handleClick={onBackToSeachAddressHandler}
          disabled={addressSearchFailed}
          className={classes.selectLocationButton}
          label={mapLabels.SearchForAddress}
          externalClassName='external-modal-button'
        />
      )}
    </div>
  );
};

AutocompleteAddressSearch.propTypes = {
  onLocationSelected: PropTypes.func,
  mapLabels: PropTypes.shape({
    placeHolder: PropTypes.string.isRequired,
    selectButton: PropTypes.string.isRequired,
    AddressNotFound: PropTypes.string.isRequired,
    SearchForAddress: PropTypes.string.isRequired,
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string.isRequired,
    addressLine3: PropTypes.string.isRequired,
    townOrCity: PropTypes.string.isRequired,
    postCode: PropTypes.string.isRequired,
    addressSearchText: PropTypes.string,
  }),
  addressName: PropTypes.string,
  locationNotListed: PropTypes.bool,
  setlocationNotListed: PropTypes.func,
};

export default AutocompleteAddressSearch;
