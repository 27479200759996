export const EventList = {
  TryReloadFileOnFailEvent: 'TryReloadFileOnFailEvent',
  UploadFailedEvent: 'UploadFailedEvent',
  RetryUploading: 'RetryUploading',
  HideRemovedFilesEvent: 'HideRemovedFilesEvent',
  SaveFilesEvent: 'SaveFilesEvent',
  SetFilePropertiesEvent: 'SetFilePropertiesEvent',
  RemoveFileEvent: 'RemoveFileEvent',
  ProvideMapSnapshot: 'ProvideMapSnapshot',
  GetDataEvent: 'GetDataEvent',
  GetDataOnResumeEvent: 'GetDataOnResumeEvent',
  SetupDataEvent: 'SetupDataEvent',
  SetupDataOnResumeEvent: 'SetupDataOnResumeEvent',
  GetTimezoneEvent: 'GetTimezoneEvent',
  SetupTimezoneEvent: 'SetupTimezoneEvent',
  VehicleData: 'VehicleData',
  RenderFilesEvent: 'RenderFilesEvent',
  SetupLineOfBusinessEvent: 'SetupLineOfBusinessEvent',
  GetLineOfBusinessEvent: 'GetLineOfBusinessEvent',
  SendLanguageData: 'SendLanguageData',
  EditAnswerEvent: 'EditAnswerEvent',
  AccidentAddressProvidedEvent: 'AccidentAddressProvidedEvent',
  ClientConfigurationEvent: 'clientConfiguration',
  ShowSuggestedActionsEvent: 'ShowSuggestedActionsEvent',
  GetUndoEvent: 'GetUndoEvent',
  UndoLastQuestion: 'UndoLastQuestion',
  ImageButtonSelectedEvent: 'ImageButtonSelectedEvent',
  Message: 'message',
  GetGoogleMapMarkersEvent: 'GetGoogleMapMarkersEvent',
  ReturnGoogleMapMarkersEvent: 'ReturnGoogleMapMarkersEvent',
  DownloadFileEvent: 'DownloadFileEvent',
  SetClaimStatus: 'SetClaimStatus',
  SendImageEvent: 'SendImageEvent',
  CompleteFileUploadSilentEvent: 'CompleteFileUploadSilentEvent',
  GuideVideoEvent: 'GuideVideoEvent',
  GetClientParamsEvent: 'GetClientParamsEvent',
  SetupClientParamsEvent: 'SetupClientParamsEvent',
  RedirectClientEvent: 'RedirectClientEvent',
  RedirectContinueClientEvent: 'RedirectContinueClientEvent',
  LoadingSpinnerPolicyDetailsEvent: 'LoadingSpinnerPolicyDetailsEvent',
  GetIdentifierFromAccessToken: "GetIdentifierFromAccessToken",
  SetIdentifierFromAccessToken: "SetIdentifierFromAccessToken"
};
