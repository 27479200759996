import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withClientConfigs } from '../../services/ClientConfigsContextProvider';
import { TenantIdList } from './utils/TenantIdList';

//TODO: When is decided to create HeaderImage configurable
// Move useStyles and tenantsImageUrls in storage account and
// get it from there so it won't need a release on any change

const useStyles = makeStyles((theme) => ({
  theaa: {
    position: 'fixed',
    display: 'flex',
    top: '0',
    left: '0',
    minWidth: '255px',
    minHeight: '134px',
    [theme.breakpoints.between(0, 1280)]: {
      display: 'none',
    },
  },
  wns: {
    position: 'fixed',
    display: 'flex',
    top: '0',
    left: '0',
    minWidth: '315px',
    minHeight: '451px',
    [theme.breakpoints.between(0, 1280)]: {
      display: 'none',
    },
  },
  aabs: {
    position: 'fixed',
    display: 'flex',
    top: '0',
    left: '0',
    minWidth: '244px',
    minHeight: '149px',
    [theme.breakpoints.between(0, 1280)]: {
      display: 'none',
    },
  },
  aioi: {
    position: 'fixed',
    display: 'flex',
    top: '0',
    left: '0',
    minWidth: '315px',
    minHeight: '131px',
    [theme.breakpoints.between(0, 1280)]: {
      display: 'none',
    },
  },
}));

const tenantsImageUrls = {
  theaa: 'https://www.theaa.com',
  wns: 'https://www.wns.com/industries/insurance/property-and-casualty-insurance-services/straight-through-processing-stp',
  aabs: 'https://www.theaa.com/business',
  aioi: 'https://www.autosaninsurance.co.uk'
};

function getTenantShortName(tenantId) {
  return Object.keys(TenantIdList).find((key) => TenantIdList[key] === tenantId);
}

const HeaderImage = ({ clientConfigs }) => {
  const classes = useStyles();
  const clientAssetsUrl = clientConfigs?.ClientAssetsUrl;
  const tenantId = clientConfigs?.Id;
  return (
    <>
      {tenantId && classes[getTenantShortName(tenantId)] ? (
        <div className={classes[getTenantShortName(tenantId)]}>
          <a href={tenantsImageUrls[getTenantShortName(tenantId)]} target='_blank' rel='noreferrer'>
            <img className = 'headerImage' src={`${clientAssetsUrl}/HeaderImage.png`} />
          </a>
        </div>
      ) : null}
    </>
  );
};

HeaderImage.propTypes = {
  clientConfigs: PropTypes.shape({
    ClaimAssistantImage: PropTypes.shape({
      ContentUrl: PropTypes.string,
    }),
    Id: PropTypes.string,
    ClientAssetsUrl: PropTypes.string,
  }),
};

export default withClientConfigs(HeaderImage);
