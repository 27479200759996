import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Box } from '@material-ui/core';
import { hooks } from 'botframework-webchat-component';
import { EventList } from '../utils/EventList';
import clsx from 'clsx';

const { useSendMessageBack, useSendEvent } = hooks;

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  figureContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '10px',
    marginRight: '10px',
  },
  imageButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageButton: {
    [theme.breakpoints.between(0, 600)]: {
      maxWidth: '160px',
      maxHeight: '160px',
      width: '37vw',
      height: '37vw',
    },
    [theme.breakpoints.between(600, 'lg')]: {
      maxWidth: '160px',
      maxHeight: '160px',
      width: '21vw',
      height: '21vw',
    },
    [theme.breakpoints.between('lg', 'xl')]: {
      width: '160px',
      height: '160px',
    },
    marginBottom: '8px',
    border: '1px solid',
    borderColor: 'black',
    '&:hover': {
      cursor: 'pointer',
      outline: '2px solid black',
      borderColor: 'black',
    }
  },
  expandButton: {
    color: 'black',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    alignSelf: 'center',
  },
}));

const ImageSelectButtons = ({ cardAction }) => {
  const [visibleActions, setVisibleActions] = useState(cardAction.value.buttons);
  const [isExpanded, setIsExpanded] = useState(false);
  const sendMessageBack = useSendMessageBack();
  const sendEvent = useSendEvent();
  const classes = useStyles();

  const visibleImagesCount = cardAction.value.initialShowedImages;
  const showLessLabel = cardAction.value.showLess;
  const showMoreLabel = cardAction.value.showMore;
  const showButton = cardAction.value.buttons?.length <= visibleImagesCount;

  useEffect(() => {
    setVisibleActions(
      isExpanded ? cardAction.value.buttons : cardAction.value.buttons.slice(0, visibleImagesCount),
    );
  }, [cardAction, visibleImagesCount, isExpanded]);

  const handleSelectedOption = useCallback(
    (button) => {
      const imgUrl = button.image;

      sendEvent(EventList.ImageButtonSelectedEvent, {
        imageUrl: imgUrl,
        name: 'imageButtonSelected',
        contentType: 'image/png',
      });

      setTimeout(() => {
        sendMessageBack(button, button.value, button.displayText);
      }, 150);
    },
    [sendMessageBack, sendEvent],
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} className={classes.imageContainer}>
        {visibleActions.map((imageBtn, index) => (
          <Grid item xs={6} sm={3} md={3} lg={3} key={index} className={classes.imageButtonContainer}>
            <figure className={classes.figureContainer}>
              <img
                className={clsx(classes.imageButton, 'image-button-option')}
                id={imageBtn.value}
                tabIndex={0}
                src={imageBtn.image}
                onClick={() => handleSelectedOption(imageBtn)}
              />
              <figcaption>{imageBtn.displayText}</figcaption>
            </figure>
          </Grid>
        ))}
      </Grid>
      <div>
        {!showButton && (
          <button
            className={clsx(classes.expandButton, 'show-more-show-less')}
            onClick={() => setIsExpanded(!isExpanded)}
            tabIndex={0}
          >
            {isExpanded ? showLessLabel : showMoreLabel}
          </button>
        )}
      </div>
    </Box>
  );
};

export default ImageSelectButtons;

ImageSelectButtons.defaultProps = {
  cardAction: undefined,
};

ImageSelectButtons.propTypes = {
  cardAction: PropTypes.object,
};
