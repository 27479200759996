export async function getSvgDataFromUrl(url) {
  try {
    const response = await fetch(url);
    const stringResponse = await response.text();
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(stringResponse, 'image/svg+xml');

    const path = svgDoc.querySelector('path');
    const pathData = path.getAttribute('d');

    return pathData;
  } catch (err) {
    console.error(err);
    return null;
  }
}
