import React, { useState, useEffect, useCallback, useContext } from 'react';
import { hooks } from 'botframework-webchat-component';
import { makeStyles } from '@material-ui/core/styles';
import CardActionButton from './CardActionButton';
import useLastActivity from '../hooks/useLastActivity';
import useFocusOnTab from '../hooks/useFocusOnTab';
import { useScrollToBottom } from '../../../components/ScrollToBottom';
import { EventList } from '../utils/EventList';
import SendBoxInputContext from '../SendBoxInput/SendBoxInputContext';
import { EditTypesList } from '../utils/EditTypes';

const { useSuggestedActions, usePostActivity } = hooks;

const useStyles = makeStyles((theme) => ({
  cardActionList: {
    textAlign: 'center',
    listStyleType: 'none',
    paddingInlineStart: 0,
    margin: theme.spacing(1, 3, 0),
  },
  shoMoreLessListItem: {
    padding: theme.spacing(1.25),
  },
  showMoreLessBtn: {
    color: '#008dff',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  buttonStyle: {
    marginBottom: theme.spacing(1),
    '&:focus': {
      outline: 'none',
    },
  },
}));

// Web Chat cleaned up the suggestedActions for us.
// If the last activity is from the bot and contains "suggestedActions", Web Chat will send it to us thru "suggestedActions".
const SuggestedActions = () => {
  const classes = useStyles();
  const scrollToBottom = useScrollToBottom();
  const [suggestedActions] = useSuggestedActions();
  const lastActivity = useLastActivity();
  const lastEvent = useLastActivity('event');
  const postActivity = usePostActivity();

  const [visibleActions, setVisibleActions] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showMoreLabel, setShowMoreLabel] = useState('Show more');
  const [showLessLabel, setShowLessLabel] = useState('Show less');
  const [maxVisibleItems, setMaxVisibleItems] = useState(10);
  const [actionsAreDisabled, setActionsAreDisabled] = useState(false);
  const { inputValue } = useContext(SendBoxInputContext);

  const setDisabledActionsCallback = useCallback(
    (disabled) => setActionsAreDisabled(disabled),
    [setActionsAreDisabled],
  );

  useEffect(() => {
    if (lastActivity && lastActivity.properties) {
      setShowMoreLabel(lastActivity.properties.showMoreLabel || showMoreLabel);
      setShowLessLabel(lastActivity.properties.showLessLabel || showLessLabel);
      setMaxVisibleItems(lastActivity.properties.initialShowedItemsCount || maxVisibleItems);
    }
    return () => setMaxVisibleItems(10);
  }, [lastActivity, showLessLabel, showMoreLabel, maxVisibleItems]);

  useEffect(() => {
    setVisibleActions(isExpanded ? suggestedActions : suggestedActions.slice(0, maxVisibleItems));
  }, [suggestedActions, isExpanded, maxVisibleItems]);

  const focusOnFirstActionButton = useFocusOnTab('first-action-item', 'button');
  useEffect(() => {
    //Avoid scroll to bottom if an edit action was performed
    if (lastEvent?.name !== EventList.EditAnswerEvent && visibleActions.length > 0) {
      scrollToBottom();
      focusOnFirstActionButton();
    }
  }, [lastEvent, visibleActions, focusOnFirstActionButton, scrollToBottom]);

  const sendMessageBackWithPdfCheck = (value, text, displayText, name = null, properties = null) => {
    let activity = {
      name,
      value,
      text,
      channelData: { messageBack: { displayText } },
      type: 'message',
      maskType: name != 'messageBack' ? lastActivity.masktype : null,
    };

    const isEditable = Object.values(EditTypesList).includes(lastActivity?.edittype);

    if (isEditable) {
      activity = {
        ...activity,
        editableKey: lastActivity.editablekey,
        arrayKey: lastActivity.arraykey,
        arrayIndexKey: lastActivity.arrayindexkey,
        validationRegex: lastActivity.validationregex,
        editLabels: lastActivity.editlabels,
        editType: lastActivity.edittype,
        dateKey: lastActivity?.datekey,
        timeKey: lastActivity?.timekey,
        validationMessage: lastActivity.validationerrormsg
          ? lastActivity.validationerrormsg
          : lastActivity.text,
        properties: properties,
        utcDateTimeProp: lastActivity.utcdatetimeprop,
        hiddenIconsStatuses: lastActivity.hiddeniconsstatuses,
      };
    }
    if (lastActivity?.pdflabel) {
      activity = {
        ...activity,
        pdfLabel: lastActivity.pdflabel,
        pdfValue: displayText,
      }
    }
    postActivity(activity);
  };

  return (
    !!visibleActions.length > 0 && (
      <ul className={classes.cardActionList}>
        {visibleActions.map((cardAction, index) => (
          <li
            key={index}
            className={classes.buttonStyle}
            data-testid='suggested-action'
            id={index === 0 ? 'first-action-item' : null}
          >
            <CardActionButton
              inputValue={inputValue}
              cardAction={cardAction}
              buttonProperties={{ disabled: actionsAreDisabled, index: index }}
              disableAllActionsCallBack={(disableActions) => setDisabledActionsCallback(disableActions)}
              sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
            />
          </li>
        ))}
        {suggestedActions.length > maxVisibleItems && (
          <li className={classes.shoMoreLessListItem}>
            <button
              className={classes.showMoreLessBtn}
              onClick={() => setIsExpanded(!isExpanded)}
              tabIndex={0}
            >
              {isExpanded ? showLessLabel : showMoreLabel}
            </button>
          </li>
        )}
      </ul>
    )
  );
};

export default SuggestedActions;
