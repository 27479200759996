import React, { useState, useCallback, useMemo } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import PropTypes from 'prop-types';
import ClaimButton from '../../../../components/ClaimButton';
import AutocompleteAddressSearch from './AutocompleteAddressSearch';
import { makeStyles } from '@material-ui/core/styles';
import LoadScriptWrapper from '../../../../components/LoadScriptWrapper';
import useLastActivity from '../../hooks/useLastActivity';
import PostcodeAddressSearch from './PostcodeAddressSearch';

const useStyles = makeStyles(() => ({
  mapModalOverlay: {
    padding: '24px',
  },
}));

const UkVehicleAddressSearch = ({
  cardAction: { displayText, value },
  sendMessageBackWithPdfCheck,
  isPostcodeAddressProviderDialog = false,
  ...buttonProperties
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [locationNotListed, setlocationNotListed] = useState(false);
  const mapLabels = JSON.parse(value);
  const classes = useStyles();
  const lastActivity = useLastActivity();

  const googleMapsUrl = useMemo(
    () => `${window.location.protocol}//${window.location.host}/api/app-config/maps-script`,
    [],
  );

  const onLocationSelectedHandler = useCallback(
    (address) => {
      sendMessageBackWithPdfCheck(
        { maskType: lastActivity.masktype },
        JSON.stringify(address),
        address[0].value,
      );
    },
    [sendMessageBackWithPdfCheck, lastActivity],
  );

  return (
    <>
      <ClaimButton handleClick={() => setIsOpen(true)} label={displayText} {...buttonProperties} />
      <div style={{ display: !isOpen ? 'none' : '' }}>
        <ModalOverlay className={classes.mapModalOverlay} open={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlayTitle style={{ marginBottom: '-28px' }}>{locationNotListed ? (mapLabels.manualHeader || mapLabels.header) : mapLabels.header}</ModalOverlayTitle>
          <ModalOverlayBody>
            <LoadScriptWrapper url={googleMapsUrl}>
              {
                isPostcodeAddressProviderDialog ?
                  <PostcodeAddressSearch
                    onLocationSelected={onLocationSelectedHandler}
                    mapLabels={mapLabels}
                    locationNotListed={locationNotListed}
                    setlocationNotListed={setlocationNotListed}
                  /> :
                  <AutocompleteAddressSearch
                    onLocationSelected={onLocationSelectedHandler}
                    mapLabels={mapLabels}
                    locationNotListed={locationNotListed}
                    setlocationNotListed={setlocationNotListed}
                  />
              }
            </LoadScriptWrapper>
          </ModalOverlayBody>
        </ModalOverlay>
      </div>
    </>
  );
};

export default UkVehicleAddressSearch;

UkVehicleAddressSearch.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  isPostcodeAddressProviderDialog: PropTypes.bool,
  sendMessageBackWithPdfCheck: PropTypes.func,
};
