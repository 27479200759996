import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hooks } from 'botframework-webchat-component';
import { EventList } from '../../utils/EventList';
import ClaimButton from '../../../../components/ClaimButton';
import { Select, ModalOverlay, Typo, colorPalette } from 'ri-components';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import { getLastEditEventByEditType } from '../../utils/activity-utils';
import { EditTypes } from '../../utils/EditTypes';
import moment from 'moment';
import { localeMap } from '../../utils/LocaleMap';
import { DateFormats } from '../../../../utils/dateUtils';

const { grey, white, black } = colorPalette;
const allMinutes = ['00', ...Array.from({ length: 59 }).map((_, idx) => idx + 1)];
const am = 'am';
const pm = 'pm';
const timeFormat12h = '12h';
const { useSendEvent, useActivities } = hooks;

const getLocalizedHour = (digit, locale = 'en-GB') =>
  digit.toLocaleString(locale, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

const useStyles = makeStyles((theme) => ({
  selectMenuList: {
    maxHeight: '320px',
  },
  paper: {
    top: '53.5vh !important',
  },
  modalTitle: {
    marginBottom: theme.spacing(2),
  },
  modalText: {
    marginBottom: theme.spacing(3.25),
  },
  toggleButtonGroup: {
    margin: '0 auto',
    width: '100%',
  },
  timePickerContainer: {
    display: 'flex',
    margin: theme.spacing(1, 0, 5),
    '& > *:first-child': {
      marginRight: '8px',
    },
  },
  selectTimeButton: {
    margin: '0 auto !IMPORTANT',
  },
  typographyClass: {
    color: 'inherit',
    fontSize: '15px',
    fontWeight: '500',
  },
  selectedMenuItem: {
    overflow: 'unset',
    backgroundColor: '#D1D1D1',
    outline: '2px auto black',
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
  menuItemRoot: {
    overflow: 'unset',
    '&[aria-selected="true"]': {
      backgroundColor: '#D1D1D1',
    },
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
    '&:focus': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
}));

const StyledToggleButton = withStyles((theme) => ({
  root: {
    backgroundColor: white,
    borderRadius: '12px',
    border: `solid 1px ${grey[4]}`,
    color: black,
    height: '56px',
    width: '100%',
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !IMPORTANT`,
    borderColor: theme.palette.primary.main,
    color: `${white} !IMPORTANT`,
  },
}))(ToggleButton);

const EditModalTimePicker = ({
  children,
  editLabels,
  openModalOverlay,
  setOpenModalOverlay,
  editableKey,
  editType,
  dateKey,
  arrayIndexKey,
  arrayKey,
  utcDateTimeProp,
}) => {
  const classes = useStyles();
  const [activities] = useActivities();

  const time = new Date();
  const [locale] = useState(
    !editLabels?.language || editLabels?.language === 'en' ? localeMap.en : editLabels?.language,
  );
  moment.locale(locale);

  const dateString = moment(time).format(DateFormats.displayFormat);
  const currentHour = time.getHours();
  const currentMinutes = time.getMinutes();
  const currentPeriodOfDay = currentHour >= 12 ? pm : am;

  // given the fact that the date is now editable, we must take the date from the activity, but only if no edit was performed on the date
  // otherwise, we take it from the editEvents, the last edit event of editType date
  const editedDate = getLastEditEventByEditType(activities, EditTypes.Date, dateKey);
  const untilNow = editedDate
    ? editedDate.value.editValue === dateString
    : editLabels.untildate === dateString;
  const sendEvent = useSendEvent();
  const initialTime = children?.split(':');
  const initialMinutes = initialTime?.pop().replace(am, '').replace(pm, '');
  const initialHour = initialTime?.pop();

  const [hour, setHour] = useState(initialHour ?? '');
  const [minute, setMinute] = useState(initialMinutes ?? '');
  const [periodOfDay, setPeriodOfDay] = useState(am);

  const timeIsPicked = hour !== '' && minute !== '';
  const selectedTime = editLabels.arepmandamnotdisplayed
    ? hour + ':' + minute + ''
    : hour + ':' + minute + periodOfDay;

  const validAmPmHour = useCallback(
    (h) => {
      if (!untilNow) return true;
      else if (currentPeriodOfDay === am && periodOfDay === pm) return false;
      else if (currentPeriodOfDay === pm && periodOfDay === am) return true;
      else if (h <= currentHour - (currentPeriodOfDay === am ? 0 : 12)) return true;
      else return false;
    },
    [periodOfDay, untilNow, currentHour, currentPeriodOfDay],
  );
  let displayText = '';

  const [hours, setHours] = useState([]);

  useEffect(() => {
    const newHours =
      displayText === timeFormat12h
        ? Array.from({ length: 12 })
            .map((_, i) => getLocalizedHour(i + 1))
            .filter(validAmPmHour)
        : Array.from({ length: 24 })
            .map((_, i) => getLocalizedHour(i))
            .filter((v) => (untilNow ? v <= currentHour : v));
    setHours(newHours);
    if (hour !== '' && !newHours.includes(hour)) {
      setHour('');
    }
  }, [displayText, validAmPmHour, untilNow, currentHour, hour]);

  const changeHour = (event) => {
    const hour = event.target.value;
    setMinute('');
    setHour(hour);
  };

  const changeMinutes = (event) => {
    const minute = event.target.value;
    let maxCurrentHour;
    if (!untilNow) {
      maxCurrentHour = 24;
      setHours(
        Array.from({ length: 24 })
          .map((_, i) => getLocalizedHour(i))
          .filter((v) => v <= maxCurrentHour),
      );
    } else if (untilNow && displayText === timeFormat12h) {
      setHours(
        Array.from({ length: 12 })
          .map((_, i) => getLocalizedHour(i + 1))
          .filter(validAmPmHour),
      );
    } else {
      maxCurrentHour = currentHour;
      setHours(
        Array.from({ length: 24 })
          .map((_, i) => getLocalizedHour(i))
          .filter((v) => v <= maxCurrentHour),
      );
    }
    setMinute(minute);
  };

  const changePeriodOfDay = (e, value) => {
    if (value) {
      setPeriodOfDay(value);
    }
  };

  const handleSubmit = useCallback(() => {
    sendEvent(EventList.EditAnswerEvent, {
      editableKey: editableKey,
      editValue: selectedTime,
      arrayKey: arrayKey,
      arrayIndexKey: arrayIndexKey,
      editType: editType,
      utcDateTimeProp: utcDateTimeProp,
      dateKey: dateKey,
    });
    setOpenModalOverlay(false);
  }, [
    sendEvent,
    editableKey,
    arrayKey,
    arrayIndexKey,
    editType,
    selectedTime,
    setOpenModalOverlay,
    utcDateTimeProp,
    dateKey,
  ]);

  const [minutes, setMinutes] = useState([]);

  useEffect(() => {
    let minutesOptions =
      JSON.parse(editLabels.minutes).length !== 0 ? JSON.parse(editLabels.minutes) : allMinutes;
    let currentHourAdjusted = currentHour.toString();
    if (currentHour.toString().length === 1) {
      currentHourAdjusted = '0' + currentHour.toString();
    }

    if (untilNow && hour === currentHourAdjusted) {
      setMinutes(minutesOptions.filter((x) => x <= currentMinutes));
    } else if (
      displayText === timeFormat12h &&
      hour === hours[hours.length - 1] &&
      periodOfDay === currentPeriodOfDay
    ) {
      setMinutes(minutesOptions.filter((x) => x <= currentMinutes));
    } else {
      setMinutes(minutesOptions);
    }
  }, [
    hour,
    currentHour,
    currentMinutes,
    untilNow,
    editLabels,
    displayText,
    hours,
    periodOfDay,
    currentPeriodOfDay,
  ]);

  return (
    <ModalOverlay open={openModalOverlay} onClose={() => setOpenModalOverlay(false)}>
      <Typo variant='bodyLg' fontWeight='medium' className={classes.modalTitle}>
        {editLabels.header}
      </Typo>
      <Typo variant='bodyMd' fontWeight='default' className={classes.modalText}>
        {editLabels.bodycontent}
      </Typo>

      {editLabels.arepmandamnotdisplayed ? (
        ''
      ) : (
        <ToggleButtonGroup
          value={periodOfDay}
          exclusive
          onChange={changePeriodOfDay}
          className={classes.toggleButtonGroup}
        >
          <StyledToggleButton disableRipple value={am}>
            <Typography classes={{ root: classes.typographyClass }}>AM</Typography>
          </StyledToggleButton>
          <StyledToggleButton disableRipple value={pm} disabled={untilNow && currentPeriodOfDay === am}>
            <Typography classes={{ root: classes.typographyClass }}>PM</Typography>
          </StyledToggleButton>
        </ToggleButtonGroup>
      )}
      <div className={classes.timePickerContainer}>
        <Select
          label={editLabels.hourlabeltext ? editLabels.hourlabeltext : 'Hr'}
          onChange={changeHour}
          value={hour}
          backgroundColor={white}
          className='modal-dropdown-sm'
          MenuProps={{
            classes: {
              list: editLabels.arepmandamnotdisplayed ? classes.selectMenuList : '',
              paper: editLabels.arepmandamnotdisplayed ? classes.paper : '',
            },
          }}
        >
          {hours &&
            hours.map((hour) => (
              <MenuItem
                key={hour}
                value={hour}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selectedMenuItem,
                }}
              >
                {hour}
              </MenuItem>
            ))}
        </Select>
        <Select
          label={editLabels.minutelabeltext ? editLabels.minutelabeltext : 'Min'}
          onChange={changeMinutes}
          className='modal-dropdown-sm'
          value={minute}
          backgroundColor={white}
          MenuProps={{
            classes: {
              list: editLabels.arepmandamnotdisplayed ? classes.selectMenuList : '',
              paper: editLabels.arepmandamnotdisplayed ? classes.paper : '',
            },
          }}
        >
          {minutes &&
            minutes.map((minute) => (
              <MenuItem
                key={minute}
                value={minute}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selectedMenuItem,
                }}
              >
                {minute}
              </MenuItem>
            ))}
        </Select>
      </div>
      <ClaimButton
        fullWidth
        className={classes.selectTimeButton}
        handleClick={handleSubmit}
        disabled={!timeIsPicked}
        label={editLabels.submittext}
        externalClassName='external-modal-button'
      />
    </ModalOverlay>
  );
};

export default EditModalTimePicker;

EditModalTimePicker.propTypes = {
  children: PropTypes.node,
  editableKey: PropTypes.string,
  arrayKey: PropTypes.string,
  arrayIndexKey: PropTypes.number,
  editLabels: PropTypes.object,
  editType: PropTypes.string,
  dateKey: PropTypes.string,
  setOpenModalOverlay: PropTypes.func,
  openModalOverlay: PropTypes.bool,
  utcDateTimeProp: PropTypes.string,
};
