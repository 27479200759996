import React from 'react';
import PropTypes from 'prop-types';
import { EditTypes } from '../../utils/EditTypes';
import EditModalTextInput from './EditModalTextInput';
import EditModalDatePicker from './EditModalDatePicker';
import EditModalTimePicker from './EditModalTimePicker';
import EditModalAddressGoogleMaps from './EditModalAddressGoogleMaps';
import EditModalRIDatePicker from './EditModalRIDatePicker';
import EditModalContactNumberProvider from './EditModalContactNumberProvider';

const EditModalSelector = ({
  editLabels,
  editType,
  editValueType,
  editableKey,
  arrayKey,
  arrayIndexKey,
  timeKey,
  dateKey,
  from,
  fromId,
  children,
  openModalOverlay,
  setOpenModalOverlay,
  properties,
  validationRegex,
  validationMessage,
  utcDateTimeProp,
  minLength,
  minLengthValidationMsg,
  maxLength,
  maxLengthValidationMsg,
  keyboardType,
}) => {
  switch (editType) {
    case EditTypes.Date:
      return (
        <EditModalDatePicker
          value={properties}
          openModalOverlay={openModalOverlay}
          setOpenModalOverlay={setOpenModalOverlay}
          editLabels={editLabels}
          editableKey={editableKey}
          editType={editType}
          timeKey={timeKey}
          arrayIndexKey={arrayIndexKey}
          arrayKey={arrayKey}
          utcDateTimeProp={utcDateTimeProp}
        >
          {children}
        </EditModalDatePicker>
      );
    case EditTypes.Time:
      return (
        <EditModalTimePicker
          openModalOverlay={openModalOverlay}
          setOpenModalOverlay={setOpenModalOverlay}
          editLabels={editLabels}
          editableKey={editableKey}
          editType={editType}
          dateKey={dateKey}
          arrayIndexKey={arrayIndexKey}
          arrayKey={arrayKey}
          value={properties}
          utcDateTimeProp={utcDateTimeProp}
        >
          {children}
        </EditModalTimePicker>
      );
    case EditTypes.DateOfBirth:
      return (
        <EditModalRIDatePicker
          value={properties}
          openModalOverlay={openModalOverlay}
          setOpenModalOverlay={setOpenModalOverlay}
          editLabels={editLabels}
          editableKey={editableKey}
          editType={editType}
          timeKey={timeKey}
          arrayIndexKey={arrayIndexKey}
          arrayKey={arrayKey}
          utcDateTimeProp={utcDateTimeProp}
        >
          {children}
        </EditModalRIDatePicker>
      );
    case EditTypes.Location:
      return (
        <EditModalAddressGoogleMaps
          openModalOverlay={openModalOverlay}
          setOpenModalOverlay={setOpenModalOverlay}
          editLabels={editLabels}
          editableKey={editableKey}
          editType={editType}
          arrayIndexKey={arrayIndexKey}
          arrayKey={arrayKey}
          value={properties}
        >
          {children}
        </EditModalAddressGoogleMaps>
      );
      case EditTypes.ContactNumber:
        return (
          <EditModalContactNumberProvider
            openModalOverlay={openModalOverlay}
            setOpenModalOverlay={setOpenModalOverlay}
            editLabels={editLabels}
            editableKey={editableKey}
            editType={editType}
            arrayIndexKey={arrayIndexKey}
            arrayKey={arrayKey}
          >
            {children}
          </EditModalContactNumberProvider>
        );
    default:
      return (
        <EditModalTextInput
          editLabels={editLabels}
          editableKey={editableKey}
          editValueType={editValueType}
          from={from}
          fromId={fromId}
          openModalOverlay={openModalOverlay}
          setOpenModalOverlay={setOpenModalOverlay}
          validationRegex={validationRegex}
          arrayIndexKey={arrayIndexKey}
          arrayKey={arrayKey}
          editType={editType}
          validationMessage={validationMessage}
          minLength={minLength}
          minLengthValidationMsg={minLengthValidationMsg}
          maxLength={maxLength}
          maxLengthValidationMsg={maxLengthValidationMsg}
          keyboardType={keyboardType}
        >
          {children}
        </EditModalTextInput>
      );
  }
};

export default EditModalSelector;

EditModalSelector.propTypes = {
  children: PropTypes.node,
  editableKey: PropTypes.string,
  editLabels: PropTypes.object,
  editType: PropTypes.string,
  editValueType: PropTypes.string,
  arrayKey: PropTypes.string,
  dateKey: PropTypes.string,
  timeKey: PropTypes.string,
  arrayIndexKey: PropTypes.number,
  from: PropTypes.string,
  fromId: PropTypes.string,
  openModalOverlay: PropTypes.bool,
  setOpenModalOverlay: PropTypes.func,
  properties: PropTypes.any,
  validationRegex: PropTypes.any,
  validationMessage: PropTypes.string,
  utcDateTimeProp: PropTypes.string,
  minLength: PropTypes.string,
  minLengthValidationMsg: PropTypes.string,
  maxLength: PropTypes.string,
  maxLengthValidationMsg: PropTypes.string,
  keyboardType: PropTypes.string,
};
