import React, { useEffect } from 'react';
import { BoxScreenCenter } from '../../../components/LoadingSuspense';
import ClaimButton from '../../../components/ClaimButton';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core';
import { useOauthApiConfigs } from '../../../services/TenantOauthContextProvider';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  resumeMessage: {
    fontSize: '17px',
  },
  resumeBtn: {
    marginTop: '20px',
  },
}));

 const AuthenticatedResumeErrorPage = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const oauthConfigs = useOauthApiConfigs();

  useEffect(() => {
    if(Cookies.get('oauth')?.language!== undefined)
    {
      const oauth = JSON.parse(Cookies.get('oauth'));
      i18n.changeLanguage(oauth.language);
    }
  }, [i18n]);
  
  return (
    <BoxScreenCenter>
      <div className={classes.resumeMessage}>{t('invalidResumeLink')}</div>
      <ClaimButton
        handleClick={() => {
          const logoutUrl = `https://${oauthConfigs.domain}/v2/logout?client_id=${oauthConfigs.clientId}&returnTo=${encodeURIComponent(oauthConfigs.logoutEndpoint)}`;
          window.location.href = logoutUrl;
        }}
        className={classes.resumeBtn}
        label={t('redirectToLogin')}
      />
    </BoxScreenCenter>
  );
};

AuthenticatedResumeErrorPage.propTypes = {
  language: PropTypes.any,
  setActivitiesCount: PropTypes.func
};

export { AuthenticatedResumeErrorPage };